<template>
  <span class="icon d-icon" :class="[size, position, 'icon-'+ icon, extraclass]" v-html="svg">
  </span>
</template>

<script>
// import axios from 'axios';
// import wrapper from 'axios-cache-plugin'

export default {
  data() {
    return {
      svg: "",
    }
  },
  props: {
    icon:{
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'is-small',
    },
    position:{
      type: String,
      default: '',
    },
    extraclass:{
      type: String,
      default: "",
    }
  },
  computed: {
    url(){
      return `/assets/icons/${this.icon}.svg`
    }
  },
  watch: {
    icon(){
      this.FetchIcon()  
    }
  },
  methods: {
    async FetchIcon(){
      try{
        const response = await fetch(this.url)
        if(response.status== 200){
          this.svg = await response.text(); 
        }
      }catch(e){
        console.error("Error descargando svg", this.icon)
      }
    }
  },  
  async mounted(){
    this.FetchIcon()
  }
}
</script>
<style lang="scss" scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>